import React, { lazy, Suspense } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import PrivateRoute from "../components/PrivateRoute";
import PayUrlRedirect from "../pages/redirecting/PayUrlRedirect";
import UserDeletePage from "../pages/deletePages/UserDeletePage";
import HomeSkeleton from "../components/skeletons/HomeSkeleton";
import KeywordProductListingPage from "../pages/productListing/KeywordProductListingPage";
// ------------- sitemap imports --------------------------------
const Sitemap = lazy(() => import("./Sitemap"));
const PredefinedDecorListPage = lazy(() =>
  import("../pages/productListing/PredefinedDecorListPage")
);
// --------------- pages imports --------------------------------
const ComingSoon = lazy(() => import("../pages/ComingSoon"));
const NotFountPage = lazy(() => import("../pages/NotFountPage"));
const HomePage = lazy(() => import("../pages/home/HomePage"));
const MyAddressPage = lazy(() => import("../pages/address/MyAddressPage"));
const SingleProductPage = lazy(() =>
  import("../pages/sngleProduct/SingleProductPage")
);
const ProductsListingPage = lazy(() =>
  import("../pages/productListing/ProductsListingPage")
);
const RedirectFromProducts = lazy(() =>
  import("../pages/redirecting/RedirectFromOldProductslistPage")
);
const PaymentPage = lazy(() => import("../pages/PaymentPage"));
const MyAccountPage = lazy(() => import("../pages/MyAccountPage"));
const Wishlist = lazy(() => import("../pages/Wishlist"));
const MyBookingsPage = lazy(() => import("../pages/bookings/MyBookingsPage"));
const OrderDetailPage = lazy(() => import("../pages/bookings/OrderDetailPage"));
const Checkout = lazy(() => import("../pages/checkout/Checkout"));
const Privacy = lazy(() => import("../components/LegalPages/Privacy"));
const CancellationAndRefunds = lazy(() =>
  import("../components/LegalPages/CancellationAndRefunds")
);
const TermAndCondition = lazy(() =>
  import("../components/LegalPages/TermAndCondition")
);
const ShippingPolicy = lazy(() =>
  import("../components/LegalPages/ShippingPolicy")
);
const NeedHelp = lazy(() => import("../components/LegalPages/NeedHelp"));
const AllFaqs = lazy(() => import("../components/LegalPages/AllFaqs"));

const EachRoute = ({ initialDataFromServer }) => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={<HomeSkeleton />}>
            <HomePage initialDataFromServer={initialDataFromServer} />
          </Suspense>
        }
      />
      <Route
        path="/sitemap.xml"
        element={
          <Suspense fallback={<Spinner />}>
            <Sitemap />
          </Suspense>
        }
      />
      <Route
        path="/profile/myaddress"
        element={
          <Suspense fallback={<Spinner />}>
            <MyAddressPage />
          </Suspense>
        }
      />
      <Route path="/profile/wishlist" element={<PrivateRoute />}>
        <Route
          path="/profile/wishlist"
          element={
            <Suspense fallback={<Spinner />}>
              <Wishlist />
            </Suspense>
          }
        />
      </Route>
      <Route path="/profile/myaccount" element={<PrivateRoute />}>
        <Route
          path="/profile/myaccount"
          element={
            <Suspense fallback={<Spinner />}>
              <MyAccountPage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/profile/referral" element={<PrivateRoute />}>
        <Route
          path="/profile/referral"
          element={
            <Suspense fallback={<Spinner />}>
              {/* <Referalls /> */}
              <ComingSoon />
            </Suspense>
          }
        />
      </Route>
      <Route path="/profile/wallet" element={<PrivateRoute />}>
        <Route
          path="/profile/wallet"
          element={
            <Suspense fallback={<Spinner />}>
              {/* <Wallet/> */}
              <ComingSoon />
            </Suspense>
          }
        />
      </Route>
      <Route
        path="/help"
        element={
          <Suspense fallback={<Spinner />}>
            {/* <Faqs /> */}
            <NeedHelp />
          </Suspense>
        }
      />
      <Route
        path="/faqs"
        element={
          <Suspense fallback={<Spinner />}>
            <AllFaqs />
          </Suspense>
        }
      />
      <Route
        path="/privacy"
        element={
          <Suspense fallback={<Spinner />}>
            <Privacy />
          </Suspense>
        }
      />
      <Route
        path="/terms"
        element={
          <Suspense fallback={<Spinner />}>
            <TermAndCondition />
          </Suspense>
        }
      />
      <Route
        path="/shippingPolicy"
        element={
          <Suspense fallback={<Spinner />}>
            <ShippingPolicy />
          </Suspense>
        }
      />
      <Route
        path="/cancellation"
        element={
          <Suspense fallback={<Spinner />}>
            <CancellationAndRefunds />
          </Suspense>
        }
      />
      <Route
        path="/checkout"
        element={
          <Suspense fallback={<Spinner />}>
            <Checkout />
          </Suspense>
        }
      />
      <Route
        path="/products/:type/:text"
        element={
          <Suspense fallback={<Spinner />}>
            <RedirectFromProducts />
          </Suspense>
        }
      />
      <Route
        path="/decors/list" // /decors/list?filter=(...json)
        element={
          <Suspense fallback={<Spinner />}>
            <ProductsListingPage />
          </Suspense>
        }
      />
      <Route
        path="/decors/predefined" //  ?filter={ids:[]}
        element={
          <Suspense fallback={<Spinner />}>
            <PredefinedDecorListPage />
          </Suspense>
        }
      />

      <Route
        path="/products/decor/:id/:productName"
        element={
          <Suspense fallback={<Spinner />}>
            <SingleProductPage initialDataFromServer={initialDataFromServer} />
          </Suspense>
        }
      />
      <Route
        path="/profile/mybookings"
        element={
          <Suspense fallback={<Spinner />}>
            <MyBookingsPage />
          </Suspense>
        }
      />
      <Route
        path="/kw/:keyword"
        element={
          <Suspense fallback={<Spinner />}>
            <KeywordProductListingPage
              initialDataFromServer={initialDataFromServer}
            />
          </Suspense>
        }
      />

      <Route
        path="/profile/mybookings/order/:orderid"
        element={
          <Suspense fallback={<Spinner />}>
            <OrderDetailPage />
          </Suspense>
        }
      />
      {/* order tracking route */}
      <Route
        path="/e/:orderid"
        element={
          <Suspense fallback={<Spinner />}>
            <OrderDetailPage />
          </Suspense>
        }
      />
      <Route path="/payment" element={<PrivateRoute />}>
        <Route
          path="/payment"
          element={
            <Suspense fallback={<Spinner />}>
              <PaymentPage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/delete" element={<Outlet />}>
        <Route
          path="user"
          element={
            <Suspense fallback={<Spinner />}>
              <UserDeletePage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/api/pay/:redirectingUrl" element={<PayUrlRedirect />} />
      <Route
        path="*"
        element={
          <Suspense fallback={<Spinner />}>
            <NotFountPage />
          </Suspense>
        }
      />
    </Routes>
  );
};

const Spinner = () => {
  return (
    <div className="fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center">
      <div className="loader w-[2rem] "></div>
    </div>
  );
};

export default EachRoute;
