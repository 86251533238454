import { Fragment, useContext } from "react";
import AuthContext from "../../../../context/AuthContext";
import OfferBanner from "./OfferBanner";
import CustomizeDecorBanner from "../../../homeComponents/CustomizeDecorBanner";
import ResponsiveContext from "../../../../context/ResponsiveContext";
import ProductListBanner from "./ProductListBanner";

export default function CustomBanner({ idx, productListingpageBanners }) {
  const { user } = useContext(AuthContext);
  const { screenWidth } = useContext(ResponsiveContext);

  return (
    <Fragment>
      {user ? (
        <>
          {idx % 16 !== 0 ? (
            <div className="w-full mx-2 md:mx-[2.5%]  md:my-8 ">
              <CustomizeDecorBanner screenWidth={screenWidth} />
            </div>
          ) : (
            <OfferBanner
              productListingpageBanners={productListingpageBanners}
              idx={idx}
            />
          )}
        </>
      ) : (
        <>
          {idx % 16 !== 0 ? (
            <div className="w-full mx-2 md:mx-[2.5%] md:my-8 ">
              <CustomizeDecorBanner screenWidth={screenWidth} />
            </div>
          ) : (
            <div className="w-full mx-2 md:mx-[2.5%] md:my-8 ">
              <ProductListBanner />
            </div>
          )}
        </>
      )}
    </Fragment>
  );
}
