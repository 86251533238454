import { useContext, useEffect } from "react";
import HomePageContext from "../context/HomepageContext";
import AddressContext from "../context/AddressContext";
import OfferContext from "../context/OfferContext";
import AuthContext from "../context/AuthContext";
import CartContext from "../context/CartContext";
import {
  getIPInfo,
  handleDefaultLocationFetch,
} from "../functions/location/handleLocation";
import CompRenderingContext from "../context/CompRenderingContext";
import { v2Api } from "../api/v2.api";
import { isBrowser, isMobile } from "react-device-detect";
import { useSearchParams } from "react-router-dom";
import { useStoreContext } from "../context/StoreContext";
import eboUtils from "../utils/eboUtils";

const useFetchEboData = () => {
  const [queries] = useSearchParams();
  const { fetchHomeData, homeInitialData } = useContext(HomePageContext);
  const { user } = useContext(AuthContext);
  const { handleCartPresence } = useContext(CartContext);
  const { setUI } = useStoreContext();
  const { fetchAddressData } = useContext(AddressContext);
  const { offerState, getOffers } = useContext(OfferContext);
  const { setLocationData, locationData } = useContext(CompRenderingContext);

  useEffect(() => {
    async function callMethods() {
      setUI((p) => ({
        ...p,
        home: {
          ...p.home,
          nav: {
            ...p.home.nav,
            backgroundThemeMode: "light",
            isDropBannerVisible: false,
          },
        },
      }));

      if (queries.get("e")) {
        let bookingId = queries.get("e");
        // if the bookingId is not starting with e, then add it at the start
        if (!bookingId.startsWith("e") && !bookingId.startsWith("E")) {
          bookingId = "E" + bookingId;
        }
        const clientDomain = process.env.REACT_APP_CLIENT_URL;
        window.location.href = `${clientDomain}/e/${bookingId}`;
      }

      const ipInfo = await handleUserLogin();
      const { hubId, locationObject } = await handleDefaultLocationFetch(
        locationData,
        ipInfo
      );

      let validAddressToAdd = locationObject;

      hubId && (await fetchHomeData({ hubId }));
      if (user) {
        const userSavedAddresses = await fetchAddressData();
        const userCart = await handleCartPresence();

        if (userCart?.bookingAddress || userSavedAddresses?.[0]) {
          validAddressToAdd =
            userCart?.bookingAddress || userSavedAddresses?.[0];

          validAddressToAdd = {
            ...(validAddressToAdd?.locationv2 || {}),
            address1: validAddressToAdd?.address1,
            address2: validAddressToAdd?.address2,
            addressLabel: validAddressToAdd?.addressLabel,
            receiversDetails: validAddressToAdd?.receiversDetails,
          };

          const serviceAreaInfo = await eboUtils().getServiceAreaInfo({
            lat: validAddressToAdd?.lat,
            lng: validAddressToAdd?.lng,
          });
          if (serviceAreaInfo.isServiceable) {
            validAddressToAdd = {
              ...validAddressToAdd,
              hubId: serviceAreaInfo.hubs?.[0]?.hubId,
            };
          }
        }
      }

      // make the dropBanner visible

      setTimeout(() => {
        setUI((p) => ({
          ...p,
          home: {
            ...p.home,
            nav: {
              ...p.home.nav,
              backgroundThemeMode: "dark",
              isDropBannerVisible: true,
            },
          },
        }));
      }, 1500);

      // setting up the location data
      setLocationData((p) => ({
        ...p,
        selectedLocation: validAddressToAdd,
      }));
      if (offerState.offers.length === 0 || !offerState.offers) {
        await getOffers();
      }
    }
    callMethods();
    // eslint-disable-next-line
  }, [user?.id]);

  const handleUserLogin = async () => {
    try {
      let ipInfo = await getIPInfo(); // will also handle the traffic loggin

      const options = {
        tags: ["user", "userLogin"],
        data: {
          registered: user?.id ? true : false,
          ipInfo: ipInfo,
          userId: user?.id,
          device: isMobile ? "mobile" : isBrowser ? "browser" : null,
          src: "site",
        },
      };

      v2Api(options);

      return ipInfo;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    async function callMethods() {
      homeInitialData?.initialData &&
        locationData?.selectedLocation?.hubId &&
        (await fetchHomeData({
          hubId: locationData?.selectedLocation?.hubId,
        }));
    }
    callMethods();
    // eslint-disable-next-line
  }, [locationData?.selectedLocation?.hubId]);

  return;
};

export default useFetchEboData;
