import React from "react";
import { useAuthContext } from "../../../../context/AuthContext";
import "../styles/price.css";
import { HiMiniArrowTrendingDown } from "react-icons/hi2";
import { useComponentRenders } from "../../../../context/CompRenderingContext";
import { BsBank2 } from "react-icons/bs";

function Price({ price }) {
  if (price.isOnBooking) {
    return (
      <>
        <p className="flex gap-3 mt-2 font-[500] text-[.825rem] items-center">
          *Price on request
        </p>
        <p className="mirror-Animation font-[500] before:w-[20px] bg-gradient-to-r  from-blue-50 to-white rounded-[15px] p-1 px-2  mt-[2px] relative right-[4px] flex items-center gap-1  text-[.75rem] text-[#2136d4]  md:text-[.825rem]">
          Enquire for price
        </p>
      </>
    );
  }

  return price.offerDiscount > 0 ? (
    <>
      <p className="flex gap-2 mt-2 whitespace-nowrap text-[.825rem] items-center">
        <span className="flex  items-center font-[600]  text-[#000000] ">
          ₹{price.sellingPrice - price.offerDiscount}
        </span>
        <span className="flex items-center font-[400]  text-[gray] line-through ">
          ₹{price?.sellingPrice}
        </span>
        <span className="flex items-center text-[#00a807] font-[400]">
          ₹{price.offerDiscount} off
        </span>
      </p>

      <OfferPrice price={price} />
    </>
  ) : (
    <>
      <p className="flex gap-2 mt-2 whitespace-nowrap text-[.875rem] items-center">
        <span className="flex items-center font-[600]  text-[#000000] ">
          ₹{price?.sellingPrice}
        </span>
        <span className="flex items-center font-[400]  text-[gray] line-through ">
          ₹{price?.costPrice}
        </span>
        <span className="flex items-center text-[#00a807] font-[400]">
          {price?.webDiscount}% off
        </span>
      </p>

      <OfferPrice price={price} />
    </>
  );
}

const OfferPrice = ({ price }) => {
  const {
    initialState: { user },
  } = useAuthContext();

  const { setLoginModal } = useComponentRenders();

  if (!user) {
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
          setLoginModal(true);
        }}
        className=" mirror-Animation w-[105%] whitespace-nowrap before:w-[20px] bg-gradient-to-r pl-2 py-1
      from-blue-100 to-white rounded-l-[15px]  font-[600] mt-[2px] relative right-[11px]
      flex items-center gap-[2px] text-[10px] text-[#2136d4]  md:text-[.8125rem]"
      >
        <span className="  mr-[1px] scale-translate-animation  ">
          ₹{price.sellingPrice - price.offerDiscount}
        </span>
        Login to drop
        <HiMiniArrowTrendingDown className="  mx-[1px] text-[12px] drop-icon-animation " />{" "}
        price
      </div>
    );
  }

  return price.offerDiscount > 0 ? (
    <div
      className="mirror-Animation w-[105%] whitespace-nowrap before:w-[20px] bg-gradient-to-r
      pl-2 py-1 from-blue-50 to-white 
      rounded-l-[15px]  font-[500]  mt-[2px] relative right-[11px]
      flex items-center gap-[2px] text-[9px] text-[#2136d4]  md:text-[.8125rem]"
    >
      <HiMiniArrowTrendingDown className="  mx-[1px] text-[12px] drop-icon-animation " />{" "}
      <div className="flex-col items-center font-[600] text-[10px] ml-[1px] ">
        <span className=" ">
          ₹
          {price.sellingPrice - price.offerDiscount - price.fullPrepaidDiscount}
        </span>
      </div>
      | Use
      <span className=" font-[600] ">{price?.offerCode}</span> +{" "}
      <BsBank2 className="relative  bottom-[1px]" />{" "}
      <span className=" font-[600] ">offer</span>
    </div>
  ) : (
    <div
      className="mirror-Animation w-[105%] whitespace-nowrap before:w-[20px] bg-gradient-to-r
    pl-2 py-1 from-blue-50 to-white 
    rounded-l-[15px]  font-[500]  mt-[2px] relative right-[11px]
    flex items-center gap-[2px] text-[9px] text-[#2136d4]  md:text-[.8125rem]"
    >
      <HiMiniArrowTrendingDown className="  mx-[1px] text-[12px] drop-icon-animation " />{" "}
      <div className="flex-col items-center font-[600] text-[10px] ml-[1px] ">
        <span className=" ">
          ₹
          {price.sellingPrice - price.offerDiscount - price.fullPrepaidDiscount}
        </span>
      </div>
      | <BsBank2 className="relative  bottom-[1px]" />{" "}
      <span className=" font-[600] ">Prepaid offer</span>
    </div>
  );
};

export default React.memo(Price);
