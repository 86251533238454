import moment from "moment";
import React, { createContext, useState, useEffect } from "react";
import copy from "copy-to-clipboard";

const GLobalStoreContext = createContext();

export const useGlobalStore = () => {
  return React.useContext(GLobalStoreContext);
};

export const GLobalStoreProvider = ({ children }) => {
  const [allOrders, setAllOrders] = useState(null);
  const [storeStates, setStoreStates] = useState({
    isGoogleMapsScriptLoaded: false,
    onScreenDraggableVideoStates: {
      isRender: false,
      isFirstTimeOpened: false,
    },
    OSPVideos: null,
    HIWExpandedView: false,
    closedOSPVideoPages: [],
    testimonials: null,
    HIWvideos: null,
  });

  const [userStates, setUserStates] = useState({
    defaultReadyTime: "",
    defaultArrivingTime: "",
    defaultCelebrationDate: moment().add(1, "days").toISOString(),
  });

  // ------- date and slot informations --------------------
  const [orderInfo, setOrderInfo] = useState({
    convenienceFee: 0,
    selectedTimeSlot: "",
    selectedDate: moment().add(1, "day").format("DD-MM-YYYY"), // it will set tomorrow date
  });

  const [wishlistedArray, setWishlistedArray] = useState([]); // stores the wishlisted product _id
  const [wishlistedArrayObj, setWishlistedArrayObj] = useState([]); // stores the wishlisted product's complete info

  const [freebiesArray, setFreebiesArray] = useState({
    isLoading: false,
    data: null,
  });

  //------------------ ** USE EFFECTS ** ------------------
  useEffect(() => {
    // adding some window functions for global access after the component is mounted
    window.copyContent = (content, msg) => {
      copy(content);
      window.toast(msg);
    };
    window.openUrl = (url) => {
      window.open(url);
    };

    // eslint-disable-next-line
  }, []);

  const handleContentLoadOnUserChange = async () => {
    // wishlist first time load
    const user = JSON.parse(localStorage?.getItem("user"));

    user && toggleWishlistItem();
  };

  const toggleWishlistItem = async (product, type) => {
    try {
      const { handleWishlistedProducts } = await import("../api/wishlistApi");
      const res = await handleWishlistedProducts({
        mode: type,
        productCode: product?._id,
      });

      if (res?.isSuccess) {
        setWishlistedArrayObj(res.wishlistedProducts);
        const resWishlistedArray = res.wishlistedProducts.map(
          (curr) => curr._id
        );
        setWishlistedArray(resWishlistedArray);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <GLobalStoreContext.Provider
      value={{
        allOrders,
        setAllOrders,
        orderInfo,
        setOrderInfo,
        wishlistedArray,
        setWishlistedArray,
        wishlistedArrayObj,
        setWishlistedArrayObj,
        toggleWishlistItem,
        freebiesArray,
        setFreebiesArray,
        userStates,
        setUserStates,
        storeStates,
        setStoreStates,
        handleContentLoadOnUserChange,
      }}
    >
      {children}
    </GLobalStoreContext.Provider>
  );
};

export default GLobalStoreContext;
