import React from "react";
import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import ResponsiveContext from "../../context/ResponsiveContext";
import eboUtils from "../../utils/eboUtils";

export default function FloatingWhatsapp({ pageName, productCode }) {
  const text = "Book on Chat";
  const { user } = useContext(AuthContext);
  const { screenWidth } = useContext(ResponsiveContext);

  let callPosition = {
    bottom:
      screenWidth <= 768 && (pageName === "home" || pageName === "product")
        ? "8rem"
        : "6rem",
    position: "fixed",
  };

  let whatsappPosition = {
    bottom:
      screenWidth <= 768 && (pageName === "home" || pageName === "product")
        ? "5rem"
        : "3rem",
    position: "fixed",
  };

  const content = {
    home: {
      content: user
        ? `Hi, I'm ${user.name}, I need help regarding product selection.`
        : "Hi, I'm new at ebo, I need help regarging product selection.",
    },
    products: {
      content: user
        ? `Hi, I'm ${user.name}, I need help regarding product selection.`
        : "Hi, I'm new at ebo, I need help regarding product selection.",
    },
    product: {
      content: user
        ? `Hi, I'm ${user.name}, I'm trying to book a product whose product code is ${productCode}.`
        : `Hi, I'm new at ebo, I'm trying to book a product whose product code is ${productCode}.`,
    },
  };

  return (
    <>
      <div
        style={callPosition}
        className=" gap-[6px] fixed -right-[6rem] mirror-Animation before:w-[20px] hover:right-[.5rem]  z-[5] px-3 py-2 blue-gradient hover:bg-gradient-to-r hover:from-blue-100 hover:to-white hover:text-[#2136d4] text-white shadow-md  rounded-full flex items-center justify-start cursor-pointer duration-[.3s]  "
      >
        <FaPhoneAlt className="text-[1.25rem] relative animate__animated animate__swing animate__infinite " />
        <Link
          to={`tel:${eboUtils().callingNumber}`}
          target="_blank"
          className="text-[1rem]  text-[#2136d4] font-medium hover:text-[#2136d4] "
        >
          Call to book
        </Link>
      </div>
      <div
        style={whatsappPosition}
        className=" gap-[6px] fixed -right-[6.9rem] mirror-Animation before:w-[20px] hover:right-[.5rem] z-[5] px-3 py-2 bg-[#31a73f] text-[white]  shadow-md  rounded-full flex items-center justify-start cursor-pointer duration-[.3s]   "
      >
        <FaWhatsapp className="text-[1.5rem] relative bottom-[1px] text-[white] animate__animated animate__tada animate__infinite " />
        <Link
          to={`https://wa.me/${eboUtils().whatsappNumber}?text=${
            content[pageName].content
          }`}
          target="_blank"
          className="text-[1rem] text-[white] font-medium hover:text-[white] "
        >
          {text}
        </Link>
      </div>
    </>
  );
}
