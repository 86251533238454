import React from "react";
import { ResponsiveProvider } from "./context/ResponsiveContext";
import { AuthProvider } from "./context/AuthContext";
import { AddonProvider } from "./context/AddonContext";
import { OfferProvider } from "./context/OfferContext";
import { AddressProvider } from "./context/AddressContext";
import { OrderProvider } from "./context/OrderContext";
import { ProductPreviewProvider } from "./context/ProductPreviewContext";
import { SingleProductProvider } from "./context/SingleProductContext";
import MainRouter from "./routes/MainRouter";
import { CompRenderingProvider } from "./context/CompRenderingContext";
/* css part */
import { CartProvider } from "./context/CartContext";
import { PaymentProvider } from "./context/PaymentContext";
import { HomePageProvider } from "./context/HomepageContext";
import { ScrollHistoryProvider } from "./context/ScrollHistoryContext";
import { GLobalStoreProvider } from "./context/GlobalStoreContext";
import ErrorBoundary from "./assets/ErrorBoundary";
import ErrorPage from "./assets/ErrorPage";
import { HelmetProvider } from "react-helmet-async";
import "./index.css";
import "./shinyball.css";
import "./App.css";
import "animate.css";
import { StoreProvider } from "./context/StoreContext";
import { ProductsListingProvider } from "./context/ProductsListingContext";

const helmetContext = {};

function App({ initialDataFromServer }) {
  return (
    <HelmetProvider context={helmetContext}>
      <ScrollHistoryProvider>
        <StoreProvider>
          <ResponsiveProvider>
            <CompRenderingProvider>
              <GLobalStoreProvider>
                <AuthProvider>
                  <ErrorBoundary fallback={<ErrorPage />}>
                    <HomePageProvider>
                      <AddonProvider>
                        <AddressProvider>
                          <OrderProvider>
                            <ProductsListingProvider>
                              <ProductPreviewProvider>
                                <CartProvider>
                                  <OfferProvider>
                                    <SingleProductProvider>
                                      <PaymentProvider>
                                        <MainRouter
                                          initialDataFromServer={
                                            initialDataFromServer
                                          }
                                        />
                                      </PaymentProvider>
                                    </SingleProductProvider>
                                  </OfferProvider>
                                </CartProvider>
                              </ProductPreviewProvider>
                            </ProductsListingProvider>
                          </OrderProvider>
                        </AddressProvider>
                      </AddonProvider>
                    </HomePageProvider>
                  </ErrorBoundary>
                </AuthProvider>
              </GLobalStoreProvider>
            </CompRenderingProvider>
          </ResponsiveProvider>
        </StoreProvider>
      </ScrollHistoryProvider>
    </HelmetProvider>
  );
}

export default App;
