//
import axios from "axios";
import moment from "moment";
import eboUtils from "../../utils/eboUtils";
import { getDistance } from "geolib";

export const handleDefaultLocationFetch = async (locationData, ipInfo) => {
  try {
    const LC_TEMP_CART = JSON.parse(localStorage?.getItem("LC_TEMP_CART"));

    // Retrieve location from localStorage
    const localstorageLocation = JSON.parse(localStorage.getItem("U_LOCATION"));
    // Set selectedLocation based on localStorage or IP geolocation
    let selectedLocation;
    let isDefaultAddress = false;

    if (locationData.selectedLocation?.lat) {
      selectedLocation = locationData.selectedLocation;
    } else if (LC_TEMP_CART?.bookingAddress?.locationv2?.lat) {
      selectedLocation = LC_TEMP_CART?.bookingAddress?.locationv2;
    } else if (localstorageLocation?.lat) {
      selectedLocation = localstorageLocation;
    } else {
      const serviceableCities = await eboUtils().getServiceableCities();

      let nearestServiceableCity = serviceableCities["mumbai"];
      let nearestDistance = null;
      if (ipInfo?.lat) {
        for (const currCityObject of Object.values(serviceableCities)) {
          const distance = getDistance(
            { latitude: ipInfo.lat, longitude: ipInfo.lng },
            {
              latitude: currCityObject.location.lat,
              longitude: currCityObject.location.lng,
            }
          );

          if (nearestDistance === null || distance < nearestDistance) {
            nearestServiceableCity = currCityObject;
            nearestDistance = distance;
          }
        }
      }

      isDefaultAddress = true;
      selectedLocation = {
        ...nearestServiceableCity,
        lat: nearestServiceableCity.location.lat,
        lng: nearestServiceableCity.location.lng,
        img: nearestServiceableCity.imageUrl,
        addressLine1: nearestServiceableCity.label,
        addressLine2: nearestServiceableCity.label + " city",
      };
    }

    let serviceAreaInfo = await eboUtils().getServiceAreaInfo({
      lat: selectedLocation.lat,
      lng: selectedLocation.lng,
    });

    // Update localStorage and state with the selected location
    localStorage.setItem(
      "U_LOCATION",
      JSON.stringify({ ...selectedLocation, isDefault: isDefaultAddress })
    );

    return {
      hubId: serviceAreaInfo.hubs?.[0]?.hubId,
      locationObject: {
        ...selectedLocation,
        hubId: serviceAreaInfo.hubs?.[0]?.hubId,
      },
    };
  } catch (error) {
    console.error(error);
  }
};

export const getIPInfo = async () => {
  try {
    let user = localStorage?.getItem("user");

    if (user) {
      user = JSON.parse(user);
    }

    const localStorageIPInfo = localStorage.getItem("I_INFO");
    let ipInfo;
    let todayTraficFlag = false;

    if (localStorageIPInfo) {
      ipInfo = JSON.parse(localStorageIPInfo); // ---> {city, state, lat, lng, date}
      // if the date is today then we will not call the api ( the traffic already logged for today)
      todayTraficFlag = !moment().isSame(ipInfo.date, "day");

      if (todayTraficFlag) {
        // update the date to today in localstorage
        let updatedIPInfo = { ...ipInfo, date: moment().toISOString() };
        localStorage.setItem("I_INFO", JSON.stringify(updatedIPInfo));
      }
    } else {
      const ipapiRes = await axios.get("https://ipapi.co/json/");
      const ipInfoFromRes = {
        city: ipapiRes?.data?.city,
        state: ipapiRes?.data?.region,
        lat: ipapiRes?.data?.latitude,
        lng: ipapiRes?.data?.longitude,
        date: moment().toISOString(),
      };
      localStorage.setItem("I_INFO", JSON.stringify(ipInfoFromRes));
      ipInfo = ipInfoFromRes;
      todayTraficFlag = true;
    }

    ipInfo["todayTraficFlag"] = todayTraficFlag;
    return ipInfo;
  } catch (error) {
    console.error(error);
    return null;
  }
};
