import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import AuthContext from "./AuthContext";

import moment from "moment";
import {
  get_orders_by_query_object,
  update_orders_by_query_object,
} from "../api/orderApi";
import GLobalStoreContext from "./GlobalStoreContext";
import CompRenderingContext from "./CompRenderingContext";
import eboUtils from "../utils/eboUtils";

const OrderContext = createContext();

export const OrderProvider = ({ children }) => {
  // ------------- ** CONTEXTS ** ------------------------
  const { user } = useContext(AuthContext);
  const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const { setAllOrders, allOrders, orderInfo, setOrderInfo } =
    useContext(GLobalStoreContext);
  const { setUniversalModalsRenderInfo } = useContext(CompRenderingContext);

  // ------------- ** STATES ** ---------------------

  const [manageOrderData, setManageOrderData] = useState([]);

  const [timeSlotData, setTimeSlotData] = useState([]);

  const [LC_TEMP_CART, setLC_TEMP_CART] = useState(null);

  // --------------- ** USE EFFECTS ** --------------------

  useEffect(() => {
    fetchTimeSlotByDate();
    // eslint-disable-next-line
  }, [orderInfo.selectedDate]);

  useEffect(() => {
    // getting local storage temp cart data after the components is mounted
    // --------------- ** LOCAL STORAGE ** ---------------
    const LC_TEMP_CART = JSON.parse(localStorage.getItem("LC_TEMP_CART"));
    if (LC_TEMP_CART) {
      setLC_TEMP_CART(LC_TEMP_CART);
    }

    // eslint-disable-next-line
  }, []);

  // --------------- ** FUNCTIONS ** --------------------

  //  in order to get the time slot data according to date
  const fetchTimeSlotByDate = async (date_) => {
    const date = date_ ? date_ : orderInfo.selectedDate;

    try {
      const res = await axios.get(
        `${REACT_APP_BACKEND_URL}/api/timeslot/${date}`
      );
      setTimeSlotData(res.data);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  // booking id creation
  const bookingIdCreate = async () => {
    const res = await fetchTimeSlotByDate(LC_TEMP_CART.bookingDate);
    const slot_ = res.slot.filter((curr) => {
      return curr.slotTime === LC_TEMP_CART.bookingTime;
    });
    const bookingIdCreation = await import("../functions/bookingIdCreation");
    const id = await bookingIdCreation(
      LC_TEMP_CART.bookingDate,
      LC_TEMP_CART.bookingTime,
      slot_[0].orderCount
    );
    return id;
  };

  const getAllOrders = async () => {
    // const orders = await   get_all_orders(user.token, REACT_APP_BACKEND_URL);
    const orders = await get_orders_by_query_object({
      BaseUrl: REACT_APP_BACKEND_URL,
      queryObject: { userId: user.id },
      token: user.token,
      skip: 0,
      limit: 50,
      sortingObj: { createdAt: "desc" },
    });
    orders.isSuccess ? setAllOrders(orders.orders) : setAllOrders(null);
  };

  // placing an order
  const placeOrder = async () => {
    try {
      const LC_TEMP_CART = JSON.parse(localStorage.getItem("LC_TEMP_CART"));

      if (LC_TEMP_CART?.bookingAddress?.locationv2.lat) {
        const serviceAreaInfo = await eboUtils().getServiceAreaInfo({
          lat: LC_TEMP_CART.bookingAddress?.locationv2?.lat,
          lng: LC_TEMP_CART.bookingAddress?.locationv2?.lng,
        });

        if (!serviceAreaInfo.isServiceable) {
          setUniversalModalsRenderInfo((p) => ({
            ...p,
            warningModel: {
              msg: "OOPs! The address you selected is not serviceable",
              rightBtnText: "Ok",
              isRender: true,
            },
          }));

          return;
        }
      }

      const res = await axios.post(
        `${REACT_APP_BACKEND_URL}/api/orders/place`,
        {
          cartId: user.id + "C",
          userId: user.id,
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      console.log(res);
      if (res.data.isSuccess) {
        setOrderInfo({
          selectedDate: moment().add(1, "day").format("DD-MM-YYYY"), // it will set tomorrow date
          selectedTimeSlot: "",
        });
        getAllOrders();
        return "placed";
      } else {
        return "not placed";
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * It is for updating the all order array whenever the user changes something related to the orders
   * @param {Object} order - The latest / updated Order object
   * @param {String} type - What kind of operation to perform. 'add' or 'update' or 'delete'
   * @returns {Object} - {isSuccess: boolean, message:String}
   */
  const update_all_orders_array = async (order, type) => {
    let newOrdersList = allOrders?.map((curr) => curr);
    // add the order
    if (type === "add") {
      newOrdersList.push(order);
    } else if (type === "update") {
      newOrdersList = allOrders?.map((curr) => {
        if (curr._id === order._id) {
          return order;
        } else {
          return curr;
        }
      });
    } else {
      newOrdersList = allOrders?.filter((curr) => curr._id !== order._id);
    }
    setAllOrders(newOrdersList);
  };

  // cancle Order
  const cancelOrder = async (orderId, reason) => {
    const bookingDetail = {
      status: "Order Cancelled",
      date: moment().toISOString(),
      reason: reason,
    };
    try {
      const res = await update_orders_by_query_object({
        queryObject: { _id: orderId },
        updateObject: {
          bookingStatus: "Order Cancelled",
          orderType: "Past Bookings",
          $push: { bookingDetails: bookingDetail },
        },
      });

      res.isSuccess && (await update_all_orders_array(res.order, "update"));
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  // reschedule order by id for user
  const rescheduleOrder = async ({
    oldSlotInfo,
    readyTime,
    arrivingTime,
    celebrationDate,
  }) => {
    try {
      const delivery = {
        readyTime,
        arrivingTime,
        celebrationDate,
      };

      const rescheduleDetail = {
        readyTime: oldSlotInfo.readyTime,
        arrivingTime: oldSlotInfo.arrivingTime,
        celebrationDate: oldSlotInfo.celebrationDate,
      };
      const res = await update_orders_by_query_object({
        queryObject: { _id: oldSlotInfo._id },
        updateObject: {
          $push: {
            rescheduleDetail: rescheduleDetail,
          },
          $set: {
            "date.delivery": delivery,
          },
        },
      });
      await update_all_orders_array(res.order, "update");
      return { isSuccess: true };
    } catch (error) {
      console.log(error);
      return { isSuccess: false };
    }
  };

  // payment status and detail of particular order change on payment done by the user
  const updateOrderPaymetStatus = async (
    paymentId,
    paymentType,
    order,
    preD
  ) => {
    const newPriceDetails = {
      ...order.priceDetail,
      prepaidDiscount: order.priceDetail.prepaidDiscount + preD,
      finalPrice: order.priceDetail.finalPrice - preD,
    };

    try {
      const res = await update_orders_by_query_object({
        queryObject: { _id: order._id },
        updateObject: {
          $set: {
            paymentStatus: paymentType,
            priceDetail: newPriceDetails,
          },
          $push: {
            paymentDetails: { paymentId: paymentId },
          },
        },
      });
      await update_all_orders_array(res.order, "update");
    } catch (error) {
      console.log(error);
    }
  };

  const updateOrderInstructions = async ({ order, instructions }) => {
    try {
      const res = await update_orders_by_query_object({
        queryObject: { _id: order._id },
        updateObject: {
          $set: {
            instructions: instructions,
          },
        },
      });

      await update_all_orders_array(res.order, "update");
    } catch (error) {
      console.log(error);
    }
  };

  /*           payment model functions                 */

  const placePayment = async ({
    amount,
    amountPending,
    pg_transaction_id,
    type_name,
    checkStatusRes,
    paymentType,
  }) => {
    const transactionId = pg_transaction_id;
    const userId = user.id;
    const paymentMode = type_name;
    const from = user.name;

    try {
      const res = await axios.post(
        `${REACT_APP_BACKEND_URL}/api/payment/placepayment`,
        {
          amount,
          paymentType,
          amountPending,
          transactionId,
          userId,
          paymentMode,
          from,
          moreDetails: checkStatusRes,
        },

        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );

      return res?.data?._id;
    } catch (error) {
      console.log(error);
    }
  };

  // get payment details by order id
  const getPaymentByOrderId = async (orderId) => {
    try {
      const res = await axios.get(
        `${REACT_APP_BACKEND_URL}/api/payment/getbyorderid/${orderId}`,
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <OrderContext.Provider
      value={{
        orderInfo,
        setOrderInfo,
        fetchTimeSlotByDate,
        getAllOrders,
        placeOrder,
        cancelOrder,
        rescheduleOrder,
        placePayment,
        getPaymentByOrderId,
        bookingIdCreate,
        timeSlotData,
        updateOrderPaymetStatus,
        manageOrderData,
        setManageOrderData,
        update_all_orders_array,
        updateOrderInstructions,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export default OrderContext;
