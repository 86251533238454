import React, { useContext, useEffect, memo, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
// --------- components imports --------------------------------
import FloatingWhatsapp from "../../assets/whatsappModal/FloatingWhatsapp";
import { getSubCategoriesList } from "./helperFn";
import { motion } from "framer-motion"; 
// --------- context imports --------------------------------
import ProductPreviewContext from "../../context/ProductPreviewContext";
import ResponsiveContext from "../../context/ResponsiveContext";
import HomePageContext from "../../context/HomepageContext";
import CompRenderingContext from "../../context/CompRenderingContext";
import KwpFooter from "../../components/productPreview/keyword.product.listing/kw.sub.com/KwpFooter";
import KwpHeader from "../../components/productPreview/keyword.product.listing/kw.sub.com/KwpHeader";
import KwpDecorList from "../../components/productPreview/keyword.product.listing/kw.sub.com/KwpDecorList";
import { v2Api } from "../../api/v2.api";

const KeywordProductListingPage = ({ initialDataFromServer }) => {
  const pageContainer = useRef(null);
  const [queries] = useSearchParams();
  const { keyword } = useParams();
  let filter = queries.get("filter");

  if (!filter) {
    filter = { category: 0 };
  } else {
    filter = JSON.parse(filter);
  }

  const isCategoryListingPage = typeof parseInt(filter.category) === "number";
  const { homeInitialData, getDecorOccassionCategoryName } =
    useContext(HomePageContext);

  const [sortParams] = useSearchParams();

  const { currPage, getProductsByFilter, setCurrUserPos } = useContext(
    ProductPreviewContext
  );

  const { screenWidth } = useContext(ResponsiveContext);
  const { locationData } = useContext(CompRenderingContext);

  const containerRef = useRef();

  let label =
    filter?.label || getDecorOccassionCategoryName(parseInt(filter.category));
  label = label.charAt(0).toUpperCase() + label.slice(1);
  // --------------- STATES ----------------
  const [states, setStates] = useState({
    headingName: "",
    subCategories: [],
    topPadding: 0,
    keyword: initialDataFromServer?.kwData?.keyword
      ? initialDataFromServer?.kwData?.keyword
      : "",
    decors: initialDataFromServer?.kwData?.decors
      ? initialDataFromServer?.kwData?.decors
      : null,
    isProductSkeleton: initialDataFromServer?.kwData?.decors ? false : true,
    kwpInformation: initialDataFromServer?.kwData
      ? initialDataFromServer?.kwData
      : null,
  });

  useEffect(() => {
    setStates((p) => ({
      ...p,
      headingName: label,
      subCategories: isCategoryListingPage
        ? getSubCategoriesList({
            homeInitialData,
            code: parseInt(filter.category),
          })
        : [],
      topPadding:
        getSubCategoriesList({
          homeInitialData,
          code: parseInt(filter.category),
        })?.length === 0
          ? 115
          : 200,
    }));
    // eslint-disable-next-line
  }, [homeInitialData]);

  useEffect(() => {
    onInitialLoad();
    // eslint-disable-next-line
  }, [keyword]);

  useEffect(() => {
    !currPage.isLast && dataRefresh();

    // eslint-disable-next-line
  }, [sortParams, locationData?.selectedLocation?.hubId]);

  useEffect(() => {
    // Retrieve Y-axis position from session storage if available and scroll to that position
    const savedPositions = JSON.parse(sessionStorage.getItem("YP")) || [];
    const yPosition = savedPositions[0];

    if (yPosition && containerRef.current) {
      containerRef.current.scrollTo(0, yPosition);
      const savedPositions = JSON.parse(sessionStorage.getItem("YP")) || [];
      savedPositions[0] = 0;
      sessionStorage.setItem("YP", JSON.stringify(savedPositions));
    }
  }, []);

  const onInitialLoad = async () => {
    try {
      // if keyword is same as previous keyword and data is already fetched then return
      if (states.keyword === keyword && states.kwpInformation) return;

      setStates((p) => ({ ...p, isProductSkeleton: true }));

      const options = {
        tags: ["KwpDecorsListing"],
        keyword: keyword,
      };
      // scroll to top of the page
      if (containerRef.current) {
        containerRef.current.scrollTo(0, 0);
      }
      const res = await v2Api(options);
      if (res.isSuccess) {
        const { decors, ...kwpInformation } = res;
        console.log("kwpInformation", kwpInformation);
        setStates((p) => ({
          ...p,
          decors,
          kwpInformation,
          keyword: keyword,
          isProductSkeleton: false,
        }));
      } else {
        window.toast("Oops! Something went wrong. Please try again later.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const dataRefresh = async () => {
    await getProductsByFilter({
      filter: filter,
    });
  };

  const onProductClickCallBack = () => {
    // Store Y-axis position in session storage
    const currentPosition = containerRef.current.scrollTop;
    const savedPositions = JSON.parse(sessionStorage.getItem("YP")) || [];
    savedPositions[0] = currentPosition;
    sessionStorage.setItem("YP", JSON.stringify(savedPositions));
  };

  return (
    <div className="md:mt-20  font-['Roboto']  fixed right-0 top-0 left-0 bottom-0 overflow-scroll scroll-bar-remove">
      <KwpHeader screenWidth={screenWidth} />

      <motion.div
        ref={containerRef}
        className={`fixed ${
          screenWidth < 977 ? "top-[3rem]" : "top-[5rem]"
        } md:pt-8 pt-2 bottom-0 left-0 right-0  overflow-y-scroll  scroll-bar-remove`}
      >
        {states.isProductSkeleton && (
          <ProductSkeletons screenWidth={screenWidth} />
        )}
        <div className=" px-[2.5%] flex flex-col mb-4 md:mb-8 ">
          <div className="text-[1.125rem]   font-[500] capitalize mt-2 md:mt-0">
            {keyword?.split("-")?.join(" ")}
          </div>
          <div className="md:text-[.875rem] text-[.8125rem]  font-[400]">
            {states?.kwpInformation?.keywordDetail?.topSubHeading}
          </div>
        </div>

        <KwpDecorList
          dataRefresh={dataRefresh}
          text={filter.category}
          setCurrUserPos={setCurrUserPos}
          pageContainer={pageContainer}
          decors={states.decors || []}
          onProductClickCallBack={onProductClickCallBack}
        />

        {/* <div className=" px-[2.5%] flex flex-col mb-8 mt-8 ">
          <pre className="text-[.875rem] robot_font font-[500]">
            {states?.kwpInformation?.keywordDetail?.bottomDescription}
          </pre>
        </div> */}

        <KwpFooter />
      </motion.div>
      <FloatingWhatsapp pageName={"products"} />
    </div>
  );
};

const ProductSkeletons = ({ screenWidth }) => {
  const skeletonsCountArray = new Array(18).fill(0);
  return (
    <div
      style={{
        rowGap: ".875rem",
      }}
      className="flex w-full  scroll-bar-remove  flex-wrap justify-evenly overflow-y-scroll "
    >
      {skeletonsCountArray.map((_, i) => {
        return (
          <div
            style={{
              width: screenWidth < 800 ? "47%" : "30%",
              minWidth: screenWidth < 800 ? "47%" : "30%",
            }}
            key={i}
            className={` rounded-lg  aspect-[1/1.1] ebo_skeleton `}
          ></div>
        );
      })}
    </div>
  );
};

export default memo(KeywordProductListingPage);
