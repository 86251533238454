import React, { useEffect, useRef } from "react";
import { useAuthContext } from "../../../../context/AuthContext";
import { useComponentRenders } from "../../../../context/CompRenderingContext";

const BANNER_IMAGE_URL_PRE_LOGIN =
  "https://img.ebonow.com/Posters/pre_loging_banner_1.mp4";
const BANNER_IMAGE_URL_POST_LOGIN = "https://img.ebonow.com/Posters/P10B.webp";

const TOP_BANNER_RATIO = 390 / 80; // width / height
function ProductListBanner() {
  const {
    initialState: { user },
  } = useAuthContext();

  const { setLoginModal } = useComponentRenders();

  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (!videoElement) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio >= 0.2) {
            // Play the video when fully visible
            videoElement.play().catch((err) => {
              console.log(`Playback error for video `, err);
            });
          } else {
            // Pause the video when not fully visible
            videoElement.pause();
          }
        });
      },
      {
        threshold: 0.2, // Changed threshold to 60%
      }
    );

    observer.observe(videoElement);

    // Cleanup function to disconnect all observers on unmount
    return () => {
      observer.disconnect();
    };

    // eslint-disable-next-line
  }, [videoRef.current]);

  if (!user) {
    return (
      <video
        src={BANNER_IMAGE_URL_PRE_LOGIN}
        onClick={() => {
          if (!user) {
            setLoginModal(true);
          }
        }}
        ref={videoRef}
        autoPlay
        loop
        muted
        preload="auto"
        playsInline={true}
        controls={false}
        style={{
          aspectRatio: TOP_BANNER_RATIO,
        }}
        controlsList="nodownload nofullscreen noremoteplayback"
        className={`   rounded-lg w-full h-full object-cover  flex-shrink-0 `}
      />
    );
  }

  return (
    <div className=" flex items-center justify-center w-full max-w-[480px] ">
      <img
        src={BANNER_IMAGE_URL_POST_LOGIN}
        alt="ebo now offer banner"
        loading="lazy"
        title="ebo now offer banner"
        style={{ aspectRatio: TOP_BANNER_RATIO }}
        className="w-[100%] border border-[#ebebeb] rounded-md"
      />
    </div>
  );
}

export default React.memo(ProductListBanner);
